import React, { useState, useRef } from 'react';
import {
  Container,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  CircularProgress
} from '@material-ui/core';
import { DateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { uploadToSFTP } from './utils/uploadToSFTP';
import StatusAlert from './components/StatusAlert';

const Wrapper = ({ children }) => (
  <Container maxWidth="xl" style={{ padding: '24px' }}>
    {children}
  </Container>
);

const isValidId = id => {
  if (id === '' || id.length >= 1) {
    return true;
  }

  return false;
};

Wrapper.propTypes = {
  children: PropTypes.object
};



function UploadComponent({ portalActivePartners, userEmail }) {
  /* Setup Form State */
  const [file, setFile] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [sessionTimestamp, setSessionTimestamp] = useState(new Date());
  const [patientUserId, setPatientUserId] = useState('');
  const [agentUserId, setAgentUserId] = useState('');
  const [uploadState, setUploadState] = useState(null);
  const [patientFirstName, setPatientFirstName] = useState('')
  const [patientLastName, setPatientLastName] = useState('')
  const [dob, setDob] = useState(null)
  const [memberProgramId, setMemberProgramId] = useState('')
  const [agentFirstName, setAgentFirstName] = useState('')
  const [agentLastName, setAgentLastName] = useState('')
  const [batchId, setBatchId] = useState('')

  /* Create a ref that will be used to reset the file input after a succesful upload. */
  const fileRef = useRef();

  /* Validate form state */
  let isValidForm = true;

  if (!file) {
    isValidForm = false;
  } else if (!isValidId(patientUserId)) {
    isValidForm = false;
  } else if (!isValidId(agentUserId)) {
    isValidForm = false;
  } else if (!isValidId(sessionId)) {
    isValidForm = false;
  }

  /* Disable submit if there is an error on the form */

  const handleSubmit = async e => {
    e.preventDefault();
    setUploadState('Uploading Session...');

    try {
      const partnerId = portalActivePartners[0];
      const userName = userEmail.split('@')[0];

      await uploadToSFTP(
        file,
        partnerId,
        batchId,
        sessionId,
        sessionTimestamp,
        patientUserId,
        agentUserId,
        userName,
        patientFirstName,
        patientLastName,
        dob,
        memberProgramId,
        agentFirstName,
        agentLastName
      );

      setUploadState('File Uploaded Successfully!');

      /* Reset the component state after a succesful upload */
      fileRef.current.value = '';
      setFile(null);
      setSessionId('');
      setBatchId('');
      setSessionTimestamp(new Date());
      setPatientUserId('');
      setPatientUserId('');
      setAgentUserId('');
      setPatientFirstName('');
      setPatientLastName('');
      setDob(null);
      setMemberProgramId('');
      setAgentFirstName('');
      setAgentLastName('');
    } catch (error) {
      /* Log the error */
      setUploadState('Error Uploading File');
      console.error(error);
    }
  };

  return (
    <Wrapper>
      <div>
        Upload File:{' '}
        <form>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Select File</InputLabel>
            <Input
              inputRef={fileRef}
              type="file"
              id="session-file"
              onChange={e => setFile(e.target.files[0])}
              inputProps={{ accept: ".wav, .mp3" }}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <DateTimePicker
              variant="inline"
              value={sessionTimestamp}
              onChange={setSessionTimestamp}
              format="yyyy-MM-dd HH:mm:ss.sss"
            />
          </FormControl>
          <FormControl margin="normal" fullWidth error={batchId.length > 256}>
            <InputLabel>Batch Id</InputLabel>
            <Input
              type="text"
              name="batch-id"
              value={batchId}
              onChange={e => setBatchId(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth error={!isValidId(sessionId)}>
            <InputLabel htmlFor="email">Partner Session ID</InputLabel>
            <Input
              type="text"
              name="session-id"
              value={sessionId}
              helperText="Some important text"
              onChange={e => setSessionId(e.target.value)}
            />
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!isValidId(patientUserId)}
          >
            <InputLabel htmlFor="email">Partner Patient/User ID</InputLabel>
            <Input
              type="text"
              name="patient-user-id"
              value={patientUserId}
              onChange={e => setPatientUserId(e.target.value)}
            />
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!isValidId(agentUserId)}
          >
            <InputLabel htmlFor="email">Partner Agent ID</InputLabel>
            <Input
              type="text"
              name="agent-user-id"
              value={agentUserId}
              onChange={e => setAgentUserId(e.target.value)}
            />
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
          >
            <InputLabel htmlFor="email">Patient First Name</InputLabel>
            <Input
              type="text"
              name="agent-user-name"
              value={patientFirstName}
              onChange={e => setPatientFirstName(e.target.value)}
            />
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
          >
            <InputLabel htmlFor="email">Patient Last Name</InputLabel>
            <Input
              type="text"
              name="agent-user-name"
              value={patientLastName}
              onChange={e => setPatientLastName(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <div>Patient Date of Birth</div>
            <KeyboardDatePicker
              variant="inline"
              value={dob}
              onChange={setDob}
              format="yyyy-MM-dd"
              placeholder="YYYY-MM-DD"
            />
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
          >
            <InputLabel htmlFor="email">Member Program ID</InputLabel>
            <Input
              type="text"
              name="agent-user-name"
              value={memberProgramId}
              onChange={e => setMemberProgramId(e.target.value)}
            />
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
          >
            <InputLabel htmlFor="email">Agent First Name</InputLabel>
            <Input
              type="text"
              name="agent-user-name"
              value={agentFirstName}
              onChange={e => setAgentFirstName(e.target.value)}
            />
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
          >
            <InputLabel htmlFor="email">Agent Last Name</InputLabel>
            <Input
              type="text"
              name="agent-user-name"
              value={agentLastName}
              onChange={e => setAgentLastName(e.target.value)}
            />
          </FormControl>
          {uploadState === 'Uploading Session...' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!isValidForm}
            >
              Submit
            </Button>
          )}
        </form>
      </div>
      {uploadState === 'Error Uploading File' || uploadState === 'File Uploaded Successfully!' ? <StatusAlert message={uploadState} /> : ''}
    </Wrapper>
  );
}

UploadComponent.propTypes = {
  portalActivePartners: PropTypes.array,
  userEmail: PropTypes.string
};

export default UploadComponent;
